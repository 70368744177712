import React from "react";

import { ReactComponent as Star } from "../media/images/svg/star.svg";
import { ReactComponent as GoogleLogo } from "../media/images/svg/google_logo.svg";

import SavantPower from "../media/images/footer-logos/savant-power.png";
import ABB from "../media/images/footer-logos/abb.png";
import Legrand from "../media/images/footer-logos/legrand.png";
import Leviton from "../media/images/footer-logos/leviton.png";
import PassAndSeymour from "../media/images/footer-logos/pass_and_seymour.png";
import SchneiderElectric from "../media/images/footer-logos/schneider_electric.png";
import Siemens from "../media/images/footer-logos/Siemens.png";
import SquareD from "../media/images/footer-logos/square-d.png";

import STSLogo from "../media/images/smart-technology-solutions-white-logo.png";
import SCSLogo from "../media/images/smart-construction-solutions-white-logo.png";

function Footer()
{
    const logos =
    [
        { src: SavantPower,         alt: "Savant Power Logo",           href: "https://www.savant.com/power"                },
        { src: ABB,                 alt: "ABB Logo",                    href: "https://global.abb/group/en"                 },
        { src: Legrand,             alt: "Legrand Logo",                href: "https://www.legrand.us/"                     },
        { src: Leviton,             alt: "Leviton Logo",                href: "https://www.leviton.com"                     },
        { src: PassAndSeymour,      alt: "Pass & Seymour Logo",         href: "https://www.legrand.us/passandseymour"       },
        { src: SchneiderElectric,   alt: "Schneider Electric Logo",     href: "https://www.se.com/us/en/"                   },
        { src: Siemens,             alt: "Siemens Logo",                href: "https://www.siemens.com"                     },
        { src: SquareD,             alt: "Square D Logo",               href: "https://www.se.com/us/en/brands/squared/"    },
    ];

    const first4Logos =     [...logos.slice(0, 4), ...logos.slice(0, 4), ...logos.slice(0, 4)];
    const last4Logos =      [...logos.slice(4, 8), ...logos.slice(4, 8), ...logos.slice(4, 8)];

    const first2Logos =     [...logos.slice(0, 2), ...logos.slice(0, 2), ...logos.slice(0, 2)];
    const second2Logos =    [...logos.slice(2, 4), ...logos.slice(2, 4), ...logos.slice(2, 4)];
    const third2Logos =     [...logos.slice(4, 6), ...logos.slice(4, 6), ...logos.slice(4, 6)];
    const last2Logos =      [...logos.slice(6, 8), ...logos.slice(6, 8), ...logos.slice(6, 8)];


    const logoStyle = {
        width: "150px",
        margin: "0 1rem",
        flexShrink: 0,
    };

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 mx-auto">
                        {/* Our Companies */}
                        <h5 className="mt-4 fw-bold text-center">Our Other Companies</h5>
                        <div className='row'>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://sts.vherngames.com/">
                                    <img src={STSLogo} style={{ width: "190px" }} alt="STS Logo" />
                                </a>
                            </div>
                            <div className="col-12 col-md-6 mb-4 d-flex justify-content-center align-items-center">
                                <a href="https://scs.vherngames.com/">
                                    <img src={SCSLogo} style={{ width: "190px" }} alt="SCS Logo" />
                                </a>
                            </div>
                        </div>

                        {/* Scrolling Logos - Desktop */}
                        <div className="d-none d-md-block py-4">
                            <h5 className="fw-bold text-center mb-3">Our Trusted Partners</h5>
                            {/* Scrolling Logos - First Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {first4Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Second Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {last4Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Scrolling Logos - Mobile */}
                        <div className="d-block d-md-none py-4">
                            <h5 className="fw-bold text-center mb-3">Our Trusted Partners</h5>
                            {/* Scrolling Logos - First Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {first2Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Second Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {second2Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Third Row */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content">
                                        {third2Logos.map((logo, index) => (
                                            <a key={`top-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer" className="d-flex justify-content-center align-items-center">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Scrolling Logos - Last Row (Reverse Direction) */}
                            <div className="col-12 mb-4">
                                <div className="footer-scroll-container">
                                    <div className="footer-scroll-content-reverse">
                                        {last2Logos.map((logo, index) => (
                                            <a key={`bottom-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer">
                                                <img src={logo.src} alt={logo.alt} style={logoStyle} />
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Second Column: Modern Links with hover effects */}
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 d-flex justify-content-center">
                        <ul className="list-unstyled text-left">
                            <li className="mb-3"><a href="/residential-services" className="animated-link">Residential Services</a></li>
                            <li className="mb-3"><a href="/commercial-services" className="animated-link">Commercial Services</a></li>
                            <li className="mb-3"><a href="/electrical-maintenance" className="animated-link">Electrical Maintenance</a></li>
                            <li className="mb-3"><a href="/power-systems" className="animated-link">Power Systems</a></li>
                            <li className="mb-3"><a href="/lighting-solutions" className="animated-link">Lighting Solutions</a></li>
                            <li className="mb-3"><a href="/energy-efficiency" className="animated-link">Energy Efficiency</a></li>
                            <li className="mb-3"><a href="/services" className="animated-link">Services</a></li>
                            <li className="mb-3"><a href="https://building-dashboard.vherngames.com/" className="animated-link">Smart Building Dashboard</a></li>
                            <li className="mb-3"><a href="/safety-standards" className="animated-link">Safety Standards</a></li>
                            <li className="mb-3"><a href="/gallery" className="animated-link">Gallery</a></li>
                            <li className="mb-3"><a href="/#contactUs" className="animated-link">Contact</a></li>
                        </ul>
                    </div>

                    {/* Third Column: Reviews and Ratings */}
                    <div className="col-lg-12 col-xl-4 mb-4 text-center order-md-last">
                        <div className="mb-3">
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                            <Star className='accentColor' />
                        </div>
                        <p className="smallLetter">Smart Electric Solutions is rated 5 out of 5 based on xx reviews from satisfied clients.</p>
                        <p>"always willing to help and fix all my problems. Joel has been a great envoy for the company. congratulations to you and to him"</p>
                        <div className="mb-2">
                            <GoogleLogo style={{ width: '3rem' }} alt="Google Logo" />
                        </div>
                        <p>– John D.</p>
                        <p className="accentColor animated-link">View full review here</p>
                    </div>
                </div>

                <div className="row">
                    <div id="divider" className="col-12 mb-3"></div>

                    {/* Social Media and Footer Links */}
                    <div className="col-12 col-md-6 mb-2 text-center text-md-left">
                        <a href="#" className="animated-link">Instagram</a> | <a href="#" className="animated-link">Twitter</a>
                    </div>
                    <div className="col-12 col-md-6 mb-2 text-center text-md-right">
                        <a href="#" className="animated-link">Privacy Policy</a> | <a href="/sitemap.xml" className="animated-link">Sitemap</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
